<template>
  <div class="form-inline sub-mr-2">
    <b-form-input ref="input" v-if="isEditing" v-model="value" @keydown="onKeyDown" style="flex-grow: 1"/>
    <button v-if="isEditing" class="btn btn-primary" @click="onKeyDown({ key: 'Enter' })">Save</button>
    <button v-if="isEditing" class="btn btn-outline-secondary" @click="isEditing = false">Cancel</button>
    <slot v-if="!isEditing"/>
    &nbsp;<font-awesome-icon icon="edit" :style="{ color: 'grey' }" size="sm" @click="startEdit()" v-if="!locked && !isEditing"/>
  </div>  
</template>

<script>
export default {
  props: {
    text: String,
    locked: { 
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    startEdit() {
      this.value = this.text;
      this.isEditing = true;
      this.$nextTick(() => this.$refs.input.focus());
    },
    onKeyDown(event) {
      if (event.key == "Enter") {
        this.$emit('change', this.value);
        this.isEditing = false;
        event.preventDefault();
      } else if (event.key == "Escape") {
        this.isEditing = false;
        event.preventDefault();
      }
    }
  },
}
</script>

<style>

</style>